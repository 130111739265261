import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import './styles.css'

interface StyleState {
  width?: string
  left?: string
  opacity?: number
}

const navLinks = [
  {
    to: '../overview',
    label: 'Overview',
    width: 90,
  },
  {
    to: '../bank-transactions',
    label: 'Bank Transactions',
    width: 142,
  },
  {
    to: '../reports',
    label: 'Reports',
    width: 73,
  },
  {
    to: '../ledger',
    label: 'General Ledger',
    width: 135,
  },
]

const readLocalStorage = () => {
  const position = localStorage.getItem('activeLinkPosition')
  if (position) {
    return JSON.parse(position)
  } else {
    return { opacity: 0 }
  }
}

export const Nav = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const navMenuRef = useRef<HTMLDivElement>(null)
  const [activeLinkStyle, setActiveLinkStyle] =
    useState<StyleState>(readLocalStorage())
  const [hoverStyle, setHoverStyle] = useState<StyleState>()

  useEffect(() => {
    updateActiveLinkPosition(location.pathname)
  }, [location.pathname])

  const updateActiveLinkPosition = (pathname: string) => {
    const activeLink = navMenuRef.current?.querySelector(
      `a[href='${pathname}']`,
    ) as HTMLAnchorElement | null
    if (activeLink) {
      const linkRect = activeLink.getBoundingClientRect()
      const navRect = navMenuRef.current?.getBoundingClientRect()
      if (navRect) {
        const newStyle = {
          width: `${linkRect.width}px`,
          left: `${linkRect.left - navRect.left}px`,
          opacity: 1,
        }
        setActiveLinkStyle(newStyle)
        localStorage.setItem('activeLinkPosition', JSON.stringify(newStyle))
      }
    }
  }

  const handleMouseEnter = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    const linkRect = e.currentTarget.getBoundingClientRect()
    const navRect = navMenuRef.current?.getBoundingClientRect()
    if (navRect) {
      setHoverStyle({
        width: `${linkRect.width}px`,
        left: `${linkRect.left - navRect.left}px`,
        opacity: 1,
      })
    }
  }

  const handleMouseLeave = () => {
    setHoverStyle({})
  }

  const handleNavLinkClick = (
    to: string,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault()
    navigate(to)
  }

  return (
    <nav className='accounting-layout__nav'>
      <div className='accounting-layout__nav__wrapper' ref={navMenuRef}>
        <div
          className='accounting-layout__nav__link--hover'
          style={hoverStyle}
        ></div>
        <div
          className='accounting-layout__nav__link-line'
          style={activeLinkStyle}
        ></div>
        {navLinks.map(link => (
          <NavLink
            key={link.to}
            to={link.to}
            className='accounting-layout__nav__link'
            onClick={e => handleNavLinkClick(link.to, e)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ width: `${link.width}px` }}
          >
            {link.label}
          </NavLink>
        ))}
      </div>
    </nav>
  )
}
