import React, { CSSProperties, useEffect, useState } from 'react'
import { Demo } from '../Root'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { Loading } from './Loading'

function isValidHex(hex: string) {
  const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  return regex.test(hex)
}

export type DateRangeDatePickerModes = 'dayRangePicker' | 'monthPicker'

const demoToCard = (demo: Demo) => {
  const cardStyle: CSSProperties = {
    width: '300px',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px',
    margin: '10px',
    border: '2px solid #000',
    borderRadius: '10px',
    boxSizing: 'border-box',
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer',
  }

  const imageStyle: CSSProperties = {
    maxWidth: '100%',
    maxHeight: '100px',
    minHeight: '100px',
    objectFit: 'contain',
  }

  const colorBlockStyleLight: CSSProperties = {
    width: '100%',
    height: '50px',
    backgroundColor:
      demo.brandColorLightHex && isValidHex(demo.brandColorLightHex)
        ? demo.brandColorLightHex
        : '#FFFFFF',
    textAlign: 'center',
    lineHeight: '50px',
    color: '#000000',
  }

  const colorBlockStyleDark: CSSProperties = {
    width: '100%',
    height: '50px',
    backgroundColor:
      demo.brandColorDarkHex && isValidHex(demo.brandColorDarkHex)
        ? demo.brandColorDarkHex
        : '#000000',
    textAlign: 'center',
    lineHeight: '50px',
    color: '#FFFFFF',
  }

  return (
    <div style={cardStyle}>
      <Link to={`/${demo.clientName}`}>
        <div>
          <p>Name: {demo.clientName}</p>
          <img
            src={demo.brandLogoUrl}
            alt={`${demo.clientName} brand logo`}
            style={imageStyle}
          />
          <div style={colorBlockStyleLight}>
            {isValidHex(demo.brandColorLightHex)
              ? 'Light Brand Color'
              : 'No Color'}
          </div>
          <div style={colorBlockStyleDark}>
            {isValidHex(demo.brandColorDarkHex)
              ? 'Dark Brand Color'
              : 'No Color'}
          </div>
          <p style={{ marginTop: 'auto' }}>
            Demo business_id: {demo.businessId ? demo.businessId : 'N/A'}
          </p>
        </div>
      </Link>
      <button
        className='admin-button'
        onClick={async () => {
          console.log(`resetting ${demo.clientName}`)
          const updateResponse = await fetch(`/api/demos/${demo.clientName}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              clientName: demo.clientName,
              businessId: null,
            }),
          })
          if (!updateResponse.ok) console.error(updateResponse)
        }}
      >
        Reset Demo
      </button>
    </div>
  )
}

const CreateDemoForm = () => {
  const [clientName, setClientName] = useState('')
  const [brandColorHex, setBrandColorHex] = useState('')
  const [brandColorLightHex, setBrandColorLightHex] = useState('')
  const [brandColorDarkHex, setBrandColorDarkHex] = useState('')
  const [brandColorTextHex, setBrandColorTextHex] = useState('')
  const [brandLogoUrl, setBrandLogoUrl] = useState('')
  const [password, setPassword] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [bookkeepingOn, setBookkeepingOn] = useState(false)
  const [showDescriptions, setShowDescriptions] = useState(false)
  const [showReceiptUploads, setShowReceiptUploads] = useState(false)
  const [showInvoicesTab, setShowInvoicesTab] = useState(false)
  const [datePickerMode, setDatePickerMode] =
    useState<DateRangeDatePickerModes | null>(null)
  const [useComparisonPnl, setUseComparisonPnl] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const response = await fetch('/api/demos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientName,
          brandColorHex,
          brandColorLightHex,
          brandColorDarkHex,
          brandColorTextHex,
          password,
          brandLogoUrl,
          businessId,
          datePickerMode,
          bookkeepingOn,
          showDescriptions,
          showReceiptUploads,
          showInvoicesTab,
          useComparisonPnl,
        }),
      })

      if (response.ok) {
        alert('Demo created successfully')
      } else {
        const errorData = await response.json()
        alert(`Failed to create demo: ${errorData.message || 'Unknown error'}`)
      }
    } catch (error) {
      console.error('Failed to submit form:', error)
      alert('Failed to create demo due to network error')
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        maxWidth: 350,
        margin: '10px',
        padding: '20px',
        border: '2px solid black',
        borderRadius: '5px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Brand Name:</label>
        <input
          type='text'
          value={clientName}
          onChange={e => setClientName(e.target.value)}
          required
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Brand Primary Color (Hex):</label>
        <input
          type='text'
          value={brandColorHex}
          onChange={e => setBrandColorHex(e.target.value)}
          required
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Brand Light Color (Hex):</label>
        <input
          type='text'
          value={brandColorLightHex}
          onChange={e => setBrandColorLightHex(e.target.value)}
          required
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Brand Dark Color (Hex):</label>
        <input
          type='text'
          value={brandColorDarkHex}
          onChange={e => setBrandColorDarkHex(e.target.value)}
          required
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Brand Text Color (Hex):</label>
        <input
          type='text'
          value={brandColorTextHex}
          onChange={e => setBrandColorTextHex(e.target.value)}
          required
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Brand Logo URL:</label>
        <input
          type='text'
          value={brandLogoUrl}
          onChange={e => setBrandLogoUrl(e.target.value)}
          required
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Demo Password:</label>
        <input
          type='text'
          value={password}
          onChange={e => setPassword(e.target.value)}
          required
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Current Business ID:</label>
        <input
          type='text'
          value={businessId}
          onChange={e => setBusinessId(e.target.value)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Date Picker Mode:</label>
        <select
          value={datePickerMode || ''}
          onChange={e =>
            setDatePickerMode(
              e.target.value !== ''
                ? (e.target.value as DateRangeDatePickerModes)
                : null,
            )
          }
        >
          <option value=''>Default</option>
          <option value='dayRangePicker'>Day Range Picker</option>
          <option value='monthPicker'>Month Picker</option>
        </select>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Bookkeeping On:</label>
        <input
          type='checkbox'
          checked={bookkeepingOn}
          onChange={e => setBookkeepingOn(e.target.checked)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Show Transaction Memos:</label>
        <input
          type='checkbox'
          checked={showDescriptions}
          onChange={e => setShowDescriptions(e.target.checked)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Show Transaction Receipts:</label>
        <input
          type='checkbox'
          checked={showReceiptUploads}
          onChange={e => setShowReceiptUploads(e.target.checked)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Show Invoices Tab:</label>
        <input
          type='checkbox'
          checked={showInvoicesTab}
          onChange={e => setShowInvoicesTab(e.target.checked)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <label>Use Comparison P&L:</label>
        <input
          type='checkbox'
          checked={useComparisonPnl}
          onChange={e => setUseComparisonPnl(e.target.checked)}
        />
      </div>
      <div style={{ textAlign: 'right' }}>
        <button className='admin-button' type='submit'>
          Create Demo
        </button>
      </div>
    </form>
  )
}

export const Admin = () => {
  const [demos, setDemos] = useState<Demo[]>([])
  const [loading, setLoading] = useState(true)
  const { getIsAdmin, logout } = useAuth()
  const navigate = useNavigate()

  if (!getIsAdmin()) {
    return <Navigate to='/login' replace />
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/demos')
        if (!response.ok) {
          throw new Error('Failed to fetch')
        }
        const rawData = await response.json()
        const formattedData: Demo[] = rawData.map((data: any) => ({
          id: data.id,
          clientName: data.client_name,
          brandColorHex: data.brand_color_hex,
          brandColorLightHex: data.brand_color_light_hex,
          brandColorDarkHex: data.brand_color_dark_hex,
          brandColorTextHex: data.brand_color_text_hex,
          brandLogoUrl: data.brand_logo_url,
          businessId: data.business_id,
        }))
        setDemos(formattedData)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching demos:', error)
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h1>Admin Dashboard</h1>
        <button
          onClick={() => {
            logout()
            navigate('/')
          }}
        >
          Log out
        </button>
      </div>
      <CreateDemoForm />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {demos.map(demo => (
          <div key={demo.id}>{demoToCard(demo)}</div>
        ))}
      </div>
    </div>
  )
}
