import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import '@layerfi/components/dist/styles/index.css'
import { Root } from './Root'
import { Admin } from './pages/Admin'
import { Login } from './pages/Login'
import { Invoices } from './pages/Invoices'
import { LandingPage } from './pages/LandingPage'
import { BankTransactions } from './pages/accounting/bank-transactions'
import { Reports } from './pages/accounting/reports'
import { Ledger } from './pages/accounting/ledger'
import { SidebarContext, useSidebar } from './contexts/SidebarContext'
import './App.css'
import './themes/AppTheme.css'

const router = createBrowserRouter([
  {
    path: '',
    element: <Navigate to='login' replace />,
  },
  {
    path: 'admin',
    element: <Admin />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: ':demoName',
    element: <Root />,
    children: [
      {
        path: '',
        element: <Navigate to='overview' replace />,
      },
      {
        path: 'overview',
        element: <LandingPage />,
      },
      {
        path: 'bank-transactions',
        element: <BankTransactions />,
      },
      {
        path: 'reports',
        element: <Reports />,
      },
      {
        path: 'ledger',
        element: <Ledger />,
      },
      {
        path: 'invoices',
        element: <Invoices />,
      },
    ],
  },
])

function App() {
  const sidebarData = useSidebar()

  return (
    <div className='root-container'>
      <SidebarContext.Provider value={sidebarData}>
        <RouterProvider router={router} />
      </SidebarContext.Provider>
    </div>
  )
}

export default App
